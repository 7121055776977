<template>

  <!-- id : contents : (S) -->
  <div id='contents' class="st-login" >
    <!-- class : content-wrap : (S) -->
    <section class="content-wrap st-center">
      <mbHeaderTitle :title="pageTitle"></mbHeaderTitle>
      <!-- class : main-wrap (S) -->
      <div class="main-wrap">
        <div class="login-find-wrap">
          <div class="tab-box">
            <router-link to="/find/findId">아이디 찾기</router-link>
            <router-link to="/find/findPwdId" class="on">비밀번호 재설정</router-link>
          </div>
          <div class="find-form-box">
            <div class="row-box open st-long">
              <div class="select-tit-box st2 mb-center">아이디를 먼저 입력해 주세요.</div>
              <div class="txt-box ">
                <div class="input-box ">
                  <span class="s-tit">아이디</span>
                  <input type="text" class="input-st1" placeholder="아이디를 입력해 주세요." v-model="userId" @keydown="onKeydown">
                </div>
              </div>
            </div>


          </div>
          <div class="btn-box"><a href="javascript:;" @click="onClickNext" class="btn-submit">다음</a></div>
        </div>
      </div>
      <!-- class : main-wrap (E) -->


    </section>
    <!-- class : content-wrap : (E) -->
  </div>
  <!-- id : contents : (E) -->

</template>

<script>
import UserApi from "@/api/UserApi";
import mbHeaderTitle from '@/layout/components/Header/mbHeaderTitle';

export default {
  name: "findPwdId",
  props: { 
    pageTitle:{
      type:String,
      default:'비밀번호 재설정'
    }, 
  }, 
  components:{
    mbHeaderTitle
  },
  data(){
    return {
      userId: ''
    }
  },
  methods:{
    onClickNext(){
      if(this.userId==''){
        return alert('아이디를 입력해주세요.');
      }

      UserApi.existsId(this.userId).then((data)=>{
        if(!data){
          alert('가입되지 않은 아이디입니다.')
        }else{
          this.$router.push({name: 'findPwd', params:{userId:this.userId}});
        }
      })
    },
    onKeydown(e){
      if(e.keyCode===13){
        this.onClickNext();
      }
    },
  }
}
</script>

<style scoped>

</style>